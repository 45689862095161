<app-navbar></app-navbar>

<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ serviceData?.title }}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-video">
            <div class="text">
                <h3>We Provide {{ serviceData?.title }} Services</h3>
                <p> <strong> {{ serviceData?.summary }}</strong></p>
                <p>{{ serviceData?.body }}</p>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>{{ serviceData?.content[0]?.title }}</h3>
                        <p [innerHTML]="serviceData?.content[0]?.description"></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/{{serviceData?.img1}}" alt="image" width="300px" height="300px">
                    </div>
                </div>

            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/{{serviceData?.img2}}" alt="image" width="300px" height="300px">
                    </div>
                </div>
                <div class="col-lg-9 col-md-12">
                    <div class="services-details-desc">
                        <h3>{{ serviceData?.content[1]?.title }}</h3>
                        <p [innerHTML]="serviceData?.content[1]?.description"></p>
                    </div>
                </div>
            </div>
        </div>   
        <div class="services-details-overview" *ngIf="serviceData?.content[2]?.title">
            <div class="row align-items-center">

                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>{{ serviceData?.content[2]?.title }}</h3>
                        <p [innerHTML]="serviceData?.content[2]?.description"></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/{{serviceData?.img3}}" alt="image" width="300px" height="300px">
                    </div>
                </div>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/{{serviceData?.img4}}" alt="image" width="300px" height="300px">
                    </div>
                </div>
                <div class="col-lg-9 col-md-12">
                    <div class="services-details-desc">
                        <h3>{{ serviceData?.content[3]?.title }}</h3>
                        <p [innerHTML]="serviceData?.content[3]?.description"></p>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>