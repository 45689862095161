import { Component, OnInit } from '@angular/core';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  categories = [
    'mobiles',
    'cryptocurrency',
    'tablets',
    'science',
    'apps',
    'internet',
    'gaming',
    'audio',
    'telecom',
    'wearables',
    'entertainment',
    'transportation',
    'pc/ laptops',
    'cameras',
    'auto',
    'social',
    'home entertainment',
    'home appliances',
    'smart home',
    'how to'
  ];

  rssData: any[] = [];
  currentPage = 1;
  totalPages = 10;

  constructor(private uwServices: UwService) { }

  ngOnInit(): void {
    this.getRssData();
    this.rssData = this.rssData.sort((a, b) => a - b);
  }

  getRssData() {
    this.uwServices.getRssFeedData().subscribe((val) => {
      if(val) {
        if(val?.item){
          val?.item.map((v: any) => {
            const rssDataNeed = {
              category: v?.category?.cdata,
              description: v?.description?.cdata,
              title: v?.title?.cdata,
              guid: v?.guid?.text,
              link: v?.link,
              published: v?.pubDate,
              image: this.getImageName(v?.category?.cdata),
            };
            if(this.categories.includes(rssDataNeed.category)) {
              this.rssData.push(rssDataNeed);
            }
          });
        }
      }
    });
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.getRssData();
  }

  getImageName(category: string) {
    let imageName = "";
    switch (category) {
      case "mobiles": {
        const data = ["mobiles.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "cryptocurrency": {
        const data = ["cryptocurrency.jpg", "cryptocurrency2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "tablets": {
        const data = ["tablets.jpg", "tablets2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "science": {
        const data = ["science.jpg", "science2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "apps": {
        const data = ["apps.jpg", "apps2.jpg", "apps3.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "internet": {
        const data = ["internet.jpg", "internet2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "gaming": {
        const data = ["gaming.jpg", "gaming2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "audio": {
        const data = ["audio.jpg", "audio2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "telecom": {
        const data = ["telecom.jpg", "telecom2.jpg", "telecom3.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "wearables": {
        const data = ["wearables.jpg", "wearables2.jpg", "wearables3.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "entertainment": {
        const data = ["entertainment.jpg", "entertainment2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "transportation": {
        const data = ["transportation.jpg", "transportation1.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "pc/ laptops": {
        const data = ["pc-laptops.jpg", "pc-laptops1.jpg", "pc-laptops2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "cameras": {
        const data = ["cameras.jpg", "cameras1.jpg", "cameras2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "auto": {
        const data = ["auto.jpg", "auto1.jpg", "auto2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "social": {
        const data = ["social.jpg", "social1.jpg", "social2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "home entertainment": {
        const data = [
          "home-entertainment.jpg",
          "home-entertainment1.jpg",
          "home-entertainment2.jpg",
        ];
        imageName = this.getRandomValue(data);
        break;
      }
      case "home appliances": {
        const data = [
          "home-appliances.jpg",
          "home-appliances1.jpg",
          "home-appliances2.jpg",
        ];
        imageName = this.getRandomValue(data);
        break;
      }
      case "smart home": {
        const data = ["smart-home.jpg", "smart-home1.jpg", "smart-home2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      case "how to": {
        const data = ["how-to.jpg", "how-to1.jpg", "how-to2.jpg"];
        imageName = this.getRandomValue(data);
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    return `assets/img/blog/${imageName}`;
  }

  getRandomValue(arra: any) {
    const random = Math.floor(Math.random() * arra.length);
    return arra[random];
  }

}
