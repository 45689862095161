import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-projects-details',
  templateUrl: './projects-details.component.html',
  styleUrls: ['./projects-details.component.scss']
})
export class ProjectsDetailsComponent implements OnInit {

  projectData: any;
  id: any;
  constructor(
    private uwServices: UwService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getProjectsById();
  }

  getProjectsById() {
    this.uwServices.getProjects().subscribe({
      next: (val: any)=> {
        if(val?.data) {
          this.projectData = val?.data.filter((v: any) => v?.id === this.id)?.[0];
        }
      },
      error: (err) =>{
        console.error(err);
        this.router.navigate(['/error']);
      }
    });
  }
}
