<app-navbar></app-navbar>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About</li> 
                    </ul>
                </div>
            </div>
        </div>
    </div> 
</div>

<section class="team-section pt-70 pb-10">
    <div class="container">
        <div class="row">
            <h2>Why choose UgohSolutions - formerly ( UgohWorld ) ?</h2>
            <p>As an IT consultancy and Recruitment Services. We optimize your business internet technology infrastructure to empower your business growth. With:</p>
            <div class="bar"></div>
            <ul class="list">
                <li><strong>Experienced and certified team:</strong> Our team of IT professionals is highly experienced and certified in the latest technologies.</li>
                <li><strong>24/7 support:</strong> We are available to help you 24/7, so you can always get the support you need.</li>
                <li><strong>Customizable solutions:</strong> We will work with you to develop a customized solution that meets your specific needs and budget.</li>
                <li><strong>Transparent pricing:</strong> We offer transparent pricing so that you know exactly what you are paying for.</li>
                <li><strong>Friendly and reliable service:</strong> We are committed to providing you with the best possible service and support.</li>
            </ul>
            <p>Contact us today to learn more about how we can help your business grow.</p>
        </div>
    </div>
</section>

<section class="about-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="assets/img/about/abt1.jpg" alt="image"> 
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>About UgohSolutions Consulting</h2>
                    <div class="bar"></div>
                    <p>Fast rising IT consulting company that provides a wide range of services to businesses of all sizes. The company has a team of experienced IT professionals who can help businesses with their IT needs, from small businesses to large enterprises.</p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our History</a></li>
                            <li><a href="#">Our Missinon</a></li>
                            <li><a href="#">Friendly Support</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>How to generate your Creative Idea With IT Business</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> The Philosopy of Business Analytics</li>
                                    <li><i class="flaticon-tick"></i> Fast Track Your Business</li>
                                    <li><i class="flaticon-tick"></i> Cloud computing and Cybersecurity</li>
                                    <li><i class="flaticon-tick"></i> Customer relationship management</li>
                                    <li><i class="flaticon-tick"></i> Artificial intelligence and Machine learning</li>
                                    <li><i class="flaticon-tick"></i> Lies & Damn Lies About Your Business</li>
                                    <li><i class="flaticon-tick"></i> The Ultimate Deal on Business</li>
                                </ul>
                                <p>We are a growing company with a bright future. The company is committed to providing its customers with the best possible service and support. ugohsolutions is a valuable asset to any business that is looking to improve its IT infrastructure and achieve its IT goals</p>
                                <a class="default-btn" routerLink="/contact">Submit a quote</a>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>Our mission statement consist of: </h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Empowering businesses</li>
                                    <li><i class="flaticon-tick"></i> Innovative and reliable</li>
                                    <li><i class="flaticon-tick"></i> Exceptional service</li>
                                    <li><i class="flaticon-tick"></i> Fostering collaboration</li>
                                    <li><i class="flaticon-tick"></i> Trusted advisor</li>
                                    <li><i class="flaticon-tick"></i> Full potential</li>
                                </ul>
                                <p>UgohSolutions empowers businesses through innovative and reliable IT consulting services, allowing them to thrive in the ever-evolving digital landscape. We are dedicated to building long-lasting partnerships by providing exceptional service, fostering collaboration, and exceeding expectations. Our mission is to be the trusted IT advisor that helps businesses achieve their full potential.</p>
                                <a class="default-btn" routerLink="/request">Submit a quote</a>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>We are confident that we can be the IT partner you need to take your business to the next level.</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Save time and money</li>
                                    <li><i class="flaticon-tick"></i> Improve efficiency and productivity</li>
                                    <li><i class="flaticon-tick"></i> Reduce risks</li>
                                    <li><i class="flaticon-tick"></i> Stay ahead of the competition</li>
                                </ul>
                                <p>We are your trusted technology partner, dedicated to helping your business thrive in the digital age. We offer a wide range of services, from IT strategy and planning to cloud computing and cybersecurity, and we are always here to support you every step of the way.</p>
                                <a class="default-btn" routerLink="/request">Submit a quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
 
<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>We encourage you to contact us with any questions you may have. We are happy to help!.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">What services does UgohSolutions offer? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <div class="tabs_item">
                                    <p>UgohSolutions offers a wide range of IT consulting services, including:</p>
                                    <ul class="list" style="list-style: none;">
                                        <li><strong>IT strategy and planning:</strong> We will help you develop a comprehensive IT strategy that aligns with your business goals.</li>
                                        <li><strong>Cloud computing:</strong> We can help you migrate your IT infrastructure to the cloud and take advantage of the scalability, cost savings, and agility it offers.</li>
                                        <li><strong>Cybersecurity:</strong> We will help you protect your data and systems from cyberattacks.</li>
                                        <li><strong>IT support:</strong> We offer 24/7/365 IT support to ensure that your business is always up and running.</li>
                                        <li><strong>Data analytics:</strong> We can help you collect, analyze, and interpret your data to gain valuable insights into your business.</li>
                                        <li><strong>Artificial intelligence and machine learning:</strong> We can help you implement AI and ML solutions to automate tasks, improve decision-making, and gain a competitive edge.</li>
                                        <li><strong>Business process automation:</strong> We can help you automate your business processes to improve efficiency and reduce costs.</li>
                                        <li><strong>Enterprise resource planning (ERP):</strong> We can help you implement an ERP system to integrate all your business processes and improve data visibility.</li>
                                        <li><strong>Customer relationship management (CRM):</strong> We can help you implement a CRM system to track and manage your interactions with customers.</li>
                                        <li><strong>Supply chain management (SCM):</strong> We can help you optimize your supply chain to improve efficiency and reduce costs.</li>
                                        <li><strong>Project management:</strong> We can help you manage your IT projects effectively.</li>
                                        <li><strong>Change management:</strong> We can help you implement changes to your IT infrastructure and processes smoothly.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What are the benefits of working with ugohsolutions? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <div class="tabs_item">
                                    <p>There are many benefits to working with UgohSolutions, including:</p>
                                    <ul class="list" style="list-style: none;">
                                        <li><strong>Expertise:</strong> Our team of IT professionals has extensive experience in a wide range of technologies.</li>
                                        <li><strong>Experience:</strong> We have a proven track record of success in helping businesses achieve their IT goals.</li>
                                        <li><strong>Customer focus:</strong> We are committed to providing our clients with the best possible service and support.</li>
                                        <li><strong>Cost-effective:</strong> We offer competitive rates for our services.</li>
                                        <li><strong>Scalability:</strong> We can scale our services to meet the needs of businesses of all sizes.</li>
                                        <li><strong>Flexibility:</strong> We offer a flexible range of services to meet your specific needs.</li>
                                        <li><strong>Security:</strong> We take data security very seriously and have implemented a number of measures to protect our clients' data.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How do I get started with ugohsolutions? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>To get started with UgohSolutions, simply contact us today for a free consultation. We will be happy to discuss your specific needs and how we can help you achieve your IT goals.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Other related questions you can ask: <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <div class="tabs_item">
                                    <p>We encourage you to ask us any question.</p>
                                    <ul class="list" style="list-style: none;">
                                        <li><strong>What are your rates?</strong></li>
                                        <li><strong>Do you offer discounts for long-term contracts?</strong></li>
                                        <li><strong>What are your service hours?</strong></li>
                                        <li><strong>What is your response time for support tickets?</strong></li>
                                        <li><strong>Do you offer training?</strong></li>
                                        <li><strong>What are your qualifications?</strong></li>
                                        <li><strong>Do you have any references?</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pt-20 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Our Partners and Affiliates </h2>
            <p>We have a wide range of affiliates and partners that help us reach a wider market, deliver innovative solutions, and provide exceptional customer service.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let partn of partnersInfo?.data; let i = index; ">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/partnar/{{partn?.img1}}" alt="image">
                        <ul class="social">
                            <li><a href="{{partn?.facebook}}" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="{{partn?.twitter}}" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="{{partn?.linkedin}}" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="{{partn?.instagram}}" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="{{partn?.tiktok}}" target="_blank"><i class="bx bxl-tiktok"></i></a></li>
                            <li><a href="{{partn?.youtube}}" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h5 class="text-wrap">{{partn?.name}}</h5>
                        <a href="{{partn?.website}}" target="_blank"><span>About {{partn?.title}}</span></a>
                    </div> 
                </div> 
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
