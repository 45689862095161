<div class="navbar-area">
    <div class="container">
        <div class="top-nav-area">
            <div class="container">
                <div class="top-nav-area-content">
                    <div class="row align-items-center pad-nav">
                        <div class="col-lg-6 col-md-6">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <i class="flaticon-phone-call top-nav-space"></i> 
                            <span class=""><a href="tel:+14127270177">+1 (412) 727-0177</a></span>  
                             | 
                            <i class="flaticon-envelope top-nav-space"></i> 
                            <span><a href="info@ugohsolutions.com">info@ugohsolutions.com</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/ugoh_solution_logo.jpg" class="logo" alt="logo">
            </a>
            <button class="navbar-toggler" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/news" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog / News</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/request" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Request quote</a></li>
                </ul>
                <div class="others-options">
                    <div class="option-item"><i class="search-btn flaticon-search"></i>
                        <i class="close-btn flaticon-cancel"></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="flaticon-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="burger-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>