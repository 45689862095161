import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '../common/email-validator.directive.ts.directive';
import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss']
})
export class RequestQuoteComponent implements OnInit{
  submitted = false;
  onChange: Function;
  progress: any;
  imgData:any;
  file: any;

  requestForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(20),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(20),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(50),
      emailValidator(),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(18),
    ]),
    completionDate: new FormControl('', [
      Validators.required,
      Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
    ]),
    description: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(5000)
    ]),
    additionalMessage: new FormControl('', [
      Validators.maxLength(2000)
    ]),
    my_file: new FormControl(''),
    acceptTerms: new FormControl(false, Validators.requiredTrue),
  });

  requestStatus: string = '';
  tempRecord: any;
  successCount = 0;

  constructor(private ref: ChangeDetectorRef) { }

    ngOnInit() {
      emailjs.init('lcmPyx6cBFlxWIbZr');
    }

    // convenience getter for easy access to form fields
    get f() { return this.requestForm.controls; }

    onSubmit() {
      this.submitted = true;
      if (this.requestForm.invalid) {
        return;
      }
      if(this.file) {
        if (/\.(pdf)$/i.test(this.file.name)) { 
          this.reader(this.file, (err, res) => {
            this.processFormSubmissionData(res);
          });
          this.ref.markForCheck();
        }
      } else {
        this.processFormSubmissionData('');
      }
    }

    onReset() {
      this.submitted = false;
      const preview = document.querySelector("#preview");
      preview.setAttribute('src', "");
      this.requestForm.reset();
    }

    registerOnChange( fn: Function ) {
      this.onChange = fn;
    }

    processFormSubmissionData(filevalue) {
      if(this.successCount > 0 && this.tempRecord === this.requestForm.getRawValue()) {
        this.setStatus('dup');
      } else {
        this.tempRecord = this.requestForm.getRawValue();
        const { firstName, lastName, email, phone, completionDate, description, additionalMessage} = this.requestForm.getRawValue() ?? {};
        const params = {
          f_name: firstName || '',
          l_name: lastName || '',
          email: email || '',
          phone: phone || '',
          complete_date: completionDate || '',
          description: description || '',
          add_msg: additionalMessage || '',
          image: filevalue || '',
        }
        emailjs.send('service_ugohsolu_eacawzf','quot_55uvqxa', params).then((res) => {
          this.setStatus('success');
          this.successCount++;
        }).catch((err) => {
          this.setStatus('error');
        });
      }
    }

    onFileChange(event) {
      this.requestStatus = '';
      const preview = <HTMLImageElement>document.querySelector("#preview");
      if(event.target.files && event.target.files.length) {
        this.file = event.target.files?.[0];
        if (/\.(pdf)$/i.test(this.file.name)) { 
          this.reader(this.file, (err, res) => {
            preview.width = 100;
            preview.height = 100;
            preview.src = res;
          });
          this.ref.markForCheck();
        } else {
          this.requestStatus = 'file'
        }
      }
    }

    setStatus(status) {
      this.requestStatus = status;
      let el = document.querySelector("#confirmation-section");
      el.scrollIntoView({behavior: 'smooth'});
      setTimeout(() => {
        this.requestStatus = '';
        this.requestForm.reset();
        this.submitted = false;
      }, 7000);
    }

    reader(file, callback) {
      const fr = new FileReader();
      fr.onload = () => callback(null, fr.result);
      fr.onerror = (err) => callback(err);
      fr.readAsDataURL(file);
    }

}
