<app-navbar></app-navbar>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-section mt-5 mb-4">
    <div class="container">
        <div class="row">
            <h4>We are IT consultancy and Recruitment Services. We optimize your business internet technology infrastructure to empower your business growth.</h4>
        </div>
    </div>
</section>

<section class="contact-box mt-3 mb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Address</h3>
                        <p>Reading, Pennsylvania. USA</p>
                        <p>Global, Worldwide</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Email</h3>
                        <a href="mailto:info@ugohsolutions.com">info@ugohsolutions.com</a>
                        <a href="mailto:services@ugohsolutions.com">services@ugohsolutions.com</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-contact-box">
                    <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>Phone</h3>
                        <a href="tel:+14127270177">+1 (412) 727-0177 </a>
                        <a href="tel:+12673677154">+1 (267) 367-7154 </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="alert alert-success" *ngIf="requestStatus === 'success'">
                    <strong>Success! :</strong> Request was submitted successfully.
                </div>
                <div class="alert alert-danger" *ngIf="requestStatus === 'error'">
                    <strong>Error! :</strong> Request failed - Please try again.
                </div>
                <div class="alert alert-warning" *ngIf="requestStatus === 'dup'">
                    <strong>Warning! :</strong> You have submitted this records already.
                </div>
                <div class="contact-text">
                    <h3>Have Any Questions About Us?</h3>
                    <p>Please use the below form to submit a request or support with us.</p>
                </div>
                <div class="contact-form" *ngIf="!['success', 'error', 'dup'].includes(requestStatus)">
                    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="sendMail()" #form="ngForm">
                        <div class="form-group">
                            <label>Name: 
                                <strong style="color: red;">*</strong>
                            </label>
                            <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Name" required minlength="3" maxlength="20" [ngClass]="{'is-invalid': submitted && contactForm.controls.name.errors && (contactForm.controls.name.dirty || contactForm.controls.name.touched)}">
                            <div *ngIf="submitted && contactForm.controls.name.invalid && (contactForm.controls.name.dirty || contactForm.controls.name.touched)" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.name.errors?.['required']">
                                  This name field is required.
                                </div>
                                <div *ngIf="contactForm.controls.name.errors?.['minlength']">
                                  This name field must have at least 3 character.
                                </div>
                                <div *ngIf="contactForm.controls.name.errors?.['maxlength']">
                                  This name field must have at most 20 characters.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Email: <strong style="color: red;">*</strong></label>
                            <input type="email" name="email" id="email" formControlName="email" class="form-control" placeholder="Your Email" required minlength="6" maxlength="50" class="form-control form-control-sm" [ngClass]="{'is-invalid': submitted && contactForm.controls.email.errors && (contactForm.controls.email.dirty || contactForm.controls.email.touched)}">
                            <div *ngIf="submitted && contactForm.controls.email.invalid && (contactForm.controls.email.dirty || contactForm.controls.email.touched)" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.email.errors?.['required']">
                                  This email field is required.
                                </div>
                                <div *ngIf="contactForm.controls.email.errors?.['minlength']">
                                  This email field must have at least 6 character.
                                </div>
                                <div *ngIf="contactForm.controls.email.errors?.['maxlength']">
                                  This email field must have at most 50 characters.
                                </div>
                                <div *ngIf="!contactForm.controls.email.errors?.['required'] && !contactForm.controls.email.errors?.['minlength'] && !contactForm.controls.email.errors?.['maxlength'] && contactForm.controls.email.errors?.['emailValidator']">
                                  Invalid email format.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Company</label>
                            <input type="text" name="company" id="company" formControlName="company" class="form-control" placeholder="Your Company (optional)">
                        </div>
                        <div class="form-group">
                            <label>Subject: <strong style="color: red;">*</strong></label>
                            <input type="text" name="msg_subject" id="msg_subject" formControlName="subject" class="form-control" placeholder="Your Subject" required minlength="3" maxlength="50" [ngClass]="{'is-invalid': submitted && contactForm.controls.subject.errors && (contactForm.controls.subject.dirty || contactForm.controls.subject.touched)}">
                            <div *ngIf="submitted && contactForm.controls.subject.invalid && (contactForm.controls.subject.dirty || contactForm.controls.subject.touched)" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.subject.errors?.['required']">
                                  This subject field is required.
                                </div>
                                <div *ngIf="contactForm.controls.subject.errors?.['minlength']">
                                  This subject field must have at least 3 character.
                                </div>
                                <div *ngIf="contactForm.controls.subject.errors?.['maxlength']">
                                  This subject field must have at most 50 characters.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Message: <strong style="color: red;">*</strong></label>
                            <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="6" placeholder="Your Message" required minlength="10" maxlength="10000" [ngClass]="{'is-invalid': submitted && contactForm.controls.message.errors && (contactForm.controls.message.dirty || contactForm.controls.message.touched)}"></textarea>
                            <div *ngIf="submitted && contactForm.controls.message.invalid && (contactForm.controls.message.dirty || contactForm.controls.message.touched)" class="invalid-feedback">
                                <div *ngIf="contactForm.controls.message.errors?.['required']">
                                  This message field is required.
                                </div>
                                <div *ngIf="contactForm.controls.message.errors?.['minlength']">
                                  This message field must have at least 10 character.
                                </div>
                                <div *ngIf="contactForm.controls.message.errors?.['maxlength']">
                                  This message field must have at most 10000 characters.
                                </div>
                            </div>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contact-main.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>