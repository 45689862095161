import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  serviceData: any;
  id: any;
  constructor(
    private uwServices: UwService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getServiceById();
  }

  getServiceById() {
    this.uwServices.getServicesData().subscribe({
      next: (val: any)=> {
        if(val?.data) {
          this.serviceData = val?.data.filter((v: any) => v?.id === this.id)?.[0];
        }
      },
      error: (err) =>{
        console.error(err);
        this.router.navigate(['/error']);
      }
    });
  }

}
