<app-navbar></app-navbar>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="conditions-section ptb-100">
    <div class="container">
        <div class="single-privacy">
            <p><strong>This Privacy Policy describes how UgohSolutions ("we", "us", or "our") collects, uses, and discloses
                    your personal information when you use our website located at ugohsolutions.com (the "Website") and
                    the services we offer (the "Services").</strong></p>

            <h3>Information We Collect:</h3>
            <ul>
                <li>
                    <p>
                        <strong>Personal Information:</strong> We collect certain personal information from you when you
                        use
                        the
                        Website and Services, such as your name, email address, phone number, company name, and job
                        title.
                        We
                        may also collect information about your device and browsing activity, such as IP address,
                        browser
                        type,
                        operating system, and pages visited. </p>
                </li>
                <li>
                    <p><strong>Usage Data:</strong> We automatically collect usage data about your interaction with the
                        Website
                        and Services, such as the pages you visit, the features you use, and the time you spend on the
                        Website.</p>
                </li>
                <li>
                    <p><strong>Cookies and Similar Technologies:</strong> We use cookies and similar technologies to
                        collect
                        information about your activity on the Website. Cookies are small files that are stored on your
                        device
                        when you visit a website. You can control your cookie settings through your browser settings.
                    </p>
                </li>
            </ul>

            <h3>How We Use Your Information:</h3>
            <ul>
                <li>
                    <p><strong>Provide and improve the Website and Services:</strong> We use your information to provide
                        you
                        with the Website and Services, including to process your requests, and
                        communicate
                        with you. We also use your information to improve the Website and Services and develop new
                        features.
                    </p>
                </li>
                <li>
                    <p><strong>Marketing and advertising:</strong> We may use your information to send you marketing and
                        promotional emails about our products and services. You can opt out of receiving these emails at
                        any
                        time by clicking on the unsubscribe link in the email.</p>
                </li>
                <li>
                    <p><strong>Analyze website usage:</strong> We use your information to analyze how you use the
                        Website
                        and
                        Services so that we can improve them.</p>
                </li>
                <li>
                    <p><strong>Comply with law and regulations:</strong> We may use your information to comply with
                        applicable
                        laws and regulations.</p>
                </li>
            </ul>
            <h3>Disclosure of Your Information:</h3>
            <ul>
                <li>
                    <p><strong>Service providers:</strong> We may share your information with third-party service
                        providers
                        who
                        help us provide the Website and Services, such as web hosting providers, Affiliates, data analytics
                        providers,
                        and
                        marketing automation providers. These service providers are only authorized to use your
                        information
                        for
                        the purposes for which we disclose it to them.</p>
                </li>
                <li>
                    <p><strong>Business transfers:</strong> We may disclose your information in connection with a
                        business
                        transaction, such as a merger, acquisition, or asset sale.</p>
                </li>
                <li>
                    <p><strong>Legal requirements:</strong> We may disclose your information if we are required to do so
                        by
                        law
                        or regulation.</p>
                </li>
            </ul>
            <h3>Your Choices:</h3>
            <ul>
                <li>
                    <p><strong>Access and update your information:</strong> You can access and update your personal
                        information
                        through resubmitting an updated information which we check the uniqueness before overwrite with existing.</p>
                </li>
                <li>
                    <p><strong>Unsubscribe from marketing emails:</strong> You can unsubscribe from receiving marketing
                        emails
                        by clicking on the unsubscribe link in the email.</p>
                </li>
                <li>
                    <p><strong>Cookies:</strong> You can control your cookie settings through your browser settings.</p>
                </li>
            </ul>
            <h3>Data Retention:</h3>
            <p>We will retain your personal information for as long as necessary to provide you with the Website and
                Services, comply with our legal obligations, and resolve disputes.</p>
            <h3>Security:</h3>
            <p>We take reasonable steps to protect your personal information from unauthorized access, disclosure,
                alteration, or destruction. However, no website or internet transmission is completely secure, so we
                cannot guarantee the security of your information.</p>
            <h3>Children's Privacy:</h3>
            <p>The Website and Services are not intended for children under the age of 13. We do not knowingly collect
                personal information from children under the age of 13.</p>
            <h3>Changes to this Privacy Policy:</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on the Website. You are advised to review this Privacy Policy periodically for any
                changes.</p>
            <h3>Contact Us:</h3>
            <p>If you have any questions about this Privacy Policy, please contact us at <strong>info@ugohsolutions.com</strong></p>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>