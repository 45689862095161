<app-navbar></app-navbar>

<div class="main-banner-area">
    <div class="home-sliders owl-carousel owl-theme">
        <div class="home-item item-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Make Real-Life Connections With IT</h1>
                            <p>At UgohSolutions we strive to be at the forefront of technology, providing its customers with the best possible internet products and services for their businesses.</p>
                            <div class="banner-btn">
                                <a routerLink="/request" class="default-btn">Request Quote</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/compu.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Software & Digital Development</h1>
                            <p>We have the IT knowledge and experience to keep your company up and running. We provide you with IT solutions, we bring that idea to reality.</p>
                            <div class="banner-btn">
                                <a routerLink="/about" class="default-btn">About Us</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/slide11.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Digital Agency & Marketing</h1>
                            <p>As a creative agency we use small shop smarts to drive big enterprise business growth.</p>
                            <div class="banner-btn">
                                <a routerLink="/services" class="default-btn">Services</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div> 
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/slide44.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-item item-bg4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>DevOps / Cloud computing</h1>
                            <p>We provides solutions to computing services such as servers, storage, databases, networking, software, analytics, and
                                intelligence. Solid enterprise DevOps teams ready to keep your app healthy and running green. </p>
                            <div class="banner-btn">
                                <a routerLink="/projects" class="default-btn">Our Projects</a>
                                <a routerLink="/contact" class="default-btn">Contact Us</a>
                            </div>
                        </div> 
                        <div class="banner-image">
                            <img src="assets/img/home/shape.png" alt="image">
                            <img src="assets/img/home/slide77.png" class="banner-img" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Our IT solution services are a broad range of services that help businesses improve their IT infrastructure and operations.</p>
            <div class="bar"></div>
        </div>
        <div class="row">

            <div class="col-lg-4 col-md-6" *ngFor="let serv of this.serviceInfo?.data">
                <div class="single-services-box">
                    <div [class]="'icon '+ serv.bgicon">
                        <i [ngClass]="serv.icon"></i>
                    </div>
                    <h3>{{ serv.title }}</h3>
                    <p>{{ serv.summary }}</p>
                    <a [routerLink]="['/services-details',serv.id]" class="read-btn">Read More</a>
                </div>
            </div>

        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
    
    <div class="services-shape">
        <img src="assets/img/cloud.png" alt="image">
    </div>
</section>


<section class="creative-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="creative-content pt-3">
                    <h3>We Specialize <span>On Providing</span> IT Services for <span>your</span> Business need</h3>
                    <div class="bar"></div>
                    <p>We are team of software engineering & architecture, business development, design, recruiting and strategy. We
                        specialize as an agency for Fortune 500 companies, small and medium scale enterprises across the globe. Our goal
                        is to improve and keep the software or application healthy and running green always.</p>
                </div>
                <div class="skill-bar" data-percentage="90%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Developement</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="80%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Design</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="90%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Digital Marketing</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="70%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Support or Maintenance</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
                <div class="skill-bar" data-percentage="70%">
                    <p class="progress-title-holder">
                        <span class="progress-title">Project Reviews and Brainstorming</span>
                        <span class="progress-number-wrapper">
                            <span class="progress-number-mark">
                                <span class="percent"></span>
                                <span class="down-arrow"></span>
                            </span>
                        </span>
                    </p>
                    <div class="progress-content-outter">
                        <div class="progress-content"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="creative-image">
                    <img src="assets/img/home/home1.jpg" alt="image">
                    <!-- <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overview-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Features</span></h2>
            <p>We pride ourselves on building strong relationships with our clients by delivering top-tier cloud management
                services, cybersecurity protection and IT consulting.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-edit"></i>
                    </div>
                    <h3>Sketch</h3>
                    <p>We define the problem, Brainstorm solutions, Select a solution, Create a sketch, Get feedback, Refine the sketch and
                        Implement the solution.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-thinking"></i>
                    </div>
                    <h3>Design</h3>
                    <p>We gather requirements, Analyze the requirements, Design the solution, Validate the design,
                        Implement the solution and Monitor and improve the solution.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Develop</h3>
                    <p>We analyze the defined problem for clients, Design the solution, Develop the solution, Deploy the solution and
                        Monitor and improve the solution.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Test</h3>
                    <p>We perform unit testing, Integration testing, System testing, Regression Testing, Performance testing and User
                        acceptance testing.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<div class="img-section">
</div>

<section class="team-section pt-100 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Our Partners and Affiliates </h2>
            <p>We have a wide range of affiliates and partners that help us reach a wider market, deliver innovative solutions, and provide exceptional customer service.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let partn of partnersInfo?.data; let i = index; ">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/partnar/{{partn?.img1}}" alt="image">
                        <ul class="social">
                            <li><a href="{{partn?.facebook}}" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="{{partn?.twitter}}" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="{{partn?.linkedin}}" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="{{partn?.instagram}}" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="{{partn?.tiktok}}" target="_blank"><i class="bx bxl-tiktok"></i></a></li>
                            <li><a href="{{partn?.youtube}}" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h5 class="text-wrap">{{partn?.name}}</h5>
                        <a href="{{partn?.website}}" target="_blank"><span>About {{partn?.title}}</span></a>
                    </div> 
                </div> 
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Tech Related <span>Latest</span> News</h2>
            <p>We are committed to providing businesses with the information and resources they need to succeed in today's digital world. We share Computer Weekly blog news, which is updated regularly with news and insights on the latest IT trends.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let rss of rssData; let ind = index;">
                <div class="single-blog-item" *ngIf="ind <= 5">
                    <div class="image">
                        <a [href]="rss?.guid" target="_blank"><img [src]="rss?.image" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>{{rss?.published | date}}</span> - <span>About {{rss?.category}}</span>
                        <h3><a [href]="rss?.guid" target="_blank">{{rss?.title}}</a></h3>
                        <p>{{rss?.description}}</p>
                        <a [href]="rss?.link" target="_blank" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>