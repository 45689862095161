<app-navbar></app-navbar>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Request Quote</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Request Quote</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="team-section pt-70 pb-10">
    <div class="container">
        <div class="row">
            <h2>Get a Free Quote for Your IT Project Today!</h2>
            <p> Let Ugoh Solutions LLC be your trusted partner for all your IT needs, also we can schedule you for free consultation to discuss about your project for more details.</p>
            <div class="bar"></div>
            <ul class="list" style="margin-left: 25px;">
                <li class="pb-2"><strong>What are your IT services:</strong> What specific IT services are you interested in? ( App development, Cybersecurity solutions, Consulting, Recruiting, etc ).</li>
                <li class="pb-2"><strong>Project goal:</strong> What are your goals for this project? (e.g., improve website functionality, increase online sales, etc.).</li>
                <li class="pb-2"><strong>Is it existing website or IT infrastructure need:</strong> Do you have an existing website or IT infrastructure? </li>
                <li class="pb-2"><strong>Timeline project:</strong> Is there a specific timeline you have in mind? </li>
                <li class="pb-2"><strong>Upload project specification:</strong> Why don't you upload a Project briefs outlining specific requirements or Existing website mockups or reference materials.</li>
            </ul>
            <p>Contact us today to learn more about how we can help with your project idea.</p>
        </div>
    </div>
</section>


<section class="contact-box pt-10 pb-70">
    <div class="container">
        <div class="row">
            <div class="card m-3">
                <h5 class="card-header">Please provide information about the project you need our services for </h5>
                <div class="card-body">
                    <div class="m-3" id="confirmation-section">
                        <div class="alert alert-success" *ngIf="requestStatus === 'success'">
                            <strong>Success! :</strong> Request was submitted successfully.
                        </div>
                        <div class="alert alert-danger" *ngIf="requestStatus === 'error'">
                            <strong>Error! :</strong> Request failed - Please try again.
                        </div>
                        <div class="alert alert-warning" *ngIf="requestStatus === 'dup'">
                            <strong>Warning! :</strong> You have submitted this records already.
                        </div>
                        <div class="alert alert-danger" *ngIf="requestStatus === 'file'">
                            <strong>Error on file upload :</strong> File format not accepted or File size should be less than 30mb.
                        </div>
                    </div>
                    <form [formGroup]="requestForm" (ngSubmit)="onSubmit()" *ngIf="!['success', 'error', 'dup'].includes(requestStatus)">
                        <div class="row pb-4">
                            <div class="form-group col-lg-4 pb-3">
                                <label>First Name: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="firstName" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.firstName?.errors}" />
                                <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="invalid-feedback">
                                    <div *ngIf="f.firstName.errors?.['required']">
                                      This first name field is required.
                                    </div>
                                    <div *ngIf="f.firstName.errors?.['minlength']">
                                      This first name field must have at least 1 character.
                                    </div>
                                    <div *ngIf="f.firstName.errors?.['maxlength']">
                                      This first name field must have at most 20 characters.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Last Name: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="lastName" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f?.lastName?.errors }" />
                                <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="invalid-feedback">
                                    <div *ngIf="f.lastName.errors?.['required']">
                                      This last name field is required.
                                    </div>
                                    <div *ngIf="f.lastName.errors?.['minlength']">
                                      This last name field must have at least 1 character.
                                    </div>
                                    <div *ngIf="f.lastName.errors?.['maxlength']">
                                      This last name field must have at most 20 characters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-4 pb-3">
                                <label>Email: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="email" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f?.email?.errors}" />
                                <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="invalid-feedback">
                                    <div *ngIf="f.email.errors?.['required']">
                                      This email field is required.
                                    </div>
                                    <div *ngIf="f.email.errors?.['minlength']">
                                      This email field must have at least 6 character.
                                    </div>
                                    <div *ngIf="f.email.errors?.['maxlength']">
                                      This email field must have at most 50 characters.
                                    </div>
                                    <div *ngIf="!f.email.errors?.['required'] && !f.email.errors?.['minlength'] && !f.email.errors?.['maxlength'] && f.email.errors?.['emailValidator']">
                                      Invalid email format.
                                    </div>
                                  </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label>Phone number: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="phone" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="invalid-feedback">
                                    <div *ngIf="f.phone.errors?.['required']">
                                      This phone field is required.
                                    </div>
                                    <div *ngIf="f.phone.errors?.['minlength']">
                                      This phone field must have at least 5 character.
                                    </div>
                                    <div *ngIf="f.phone.errors?.['maxlength']">
                                      This phone field must have at most 18 characters.
                                    </div>
                                    <div *ngIf="!f.phone.errors?.['required'] && !f.phone.errors?.['minlength'] && !f.phone.errors?.['maxlength'] && f.phone.errors?.['pattern']">
                                      Invalid phone format.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-4">
                                <label>Project completion expected date: <strong style="color: red;">*</strong></label>
                                <input type="date" formControlName="completionDate" class="form-control" required [ngClass]="{ 'is-invalid': submitted && (f?.completionDate?.errors?.required || f?.completionDate?.errors?.pattern) }" />
                                <div *ngIf="f.completionDate.invalid && (f.completionDate.dirty || f.completionDate.touched)" class="invalid-feedback">
                                    <div *ngIf="f.completionDate.errors?.['required']">
                                      This completion date field is required.
                                    </div>
                                    <div *ngIf="!f.completionDate.errors?.['required'] && f.completionDate.errors?.['pattern']">
                                      Invalid date format.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6">
                                <label>Requesting Information Regarding <strong style="color: red;">*</strong></label>
                                <textarea name="description" class="form-control" formControlName="description"  id="description" class="form-control" required cols="30" rows="6" minlength="10" maxlength="5000" [ngClass]="{ 'is-invalid': submitted && f?.description?.errors}" ></textarea> 
                                <div *ngIf="f.description.invalid && (f.description.dirty || f.description.touched)" class="invalid-feedback">
                                    <div *ngIf="f.description.errors?.['required']">
                                      This request information field is required.
                                    </div>
                                    <div *ngIf="f.description.errors?.['minlength']">
                                      This request information field must have at least 10 character.
                                    </div>
                                    <div *ngIf="f.description.errors?.['maxlength']">
                                      This request information field must have at most 5000 characters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6">
                                <label>Additional Instructions</label>
                                <textarea name="additionalMessage" formControlName="additionalMessage" class="form-control" id="additionalMessage" class="form-control" cols="30" rows="6" maxlength="2000" [ngClass]="{ 'is-invalid': submitted && f?.additionalMessage?.errors?.maxlength }"></textarea>
                                <div *ngIf="f.additionalMessage.invalid && (f.additionalMessage.dirty || f.additionalMessage.touched)" class="invalid-feedback">
                                    <div *ngIf="f.additionalMessage.errors?.['maxlength']">
                                      This additional information must have at most 2000 characters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6">
                                <label class="mb-2">Upload PDF document ( If any ) </label><br>
                                <input class="file-input" type="file" name="my_file" formControlName="my_file" (change)="onFileChange($event)" />
                                <img src="" height="200" id="preview"/> 
                                <div style="color: red;" *ngIf="requestStatus === 'file'">
                                    PDF file only
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-check mb-3 hover-text">
                            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                            <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions: <strong style="color: red;">*</strong></label>
                            <span class="tooltip-text top">By submitting your project details, you acknowledge that you have read, understood, and agree to our <a routerLink="/terms-conditions" style="color: blue" target="_blank"> terms and conditions</a>.</span>
                            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Acknowledgment is required</div>
                        </div>
                        <div class="text-left mb-3">
                            <button class="btn btn-primary mr-1">Submit request</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>