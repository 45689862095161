<app-navbar></app-navbar>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{projectData?.name}}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="project-details-image">
                    <img src="assets/img/protfolio/{{projectData?.img1}}" alt="projects">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="projects-details-desc">

                    <h3>{{ projectData?.summary }}</h3>
                    <p> {{ projectData?.body }}</p>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Core Development Skills: </h4>
                        <p>{{ projectData?.techskills }}</p>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> What we did: </h4>
                        <p>{{ projectData?.wedid }}</p>
                    </div>
                <div class="project-details-info">
                    <div class="single-info-box">
                        <a [href]="projectData?.website" target="_blank" class="default-btn">Check them out</a>
                    </div>
                </div>
                      
                    <!-- <div class="project-details-info">
                        <div class="single-info-box">
                            <h4>Client</h4>
                            <span>James Anderson</span>
                        </div>
                        <div class="single-info-box">
                            <h4>Category</h4>
                            <span>Network, Marketing</span>
                        </div>
                        <div class="single-info-box">
                            <h4>Date</h4>
                            <span>February 28, 2023</span>
                        </div>
                        <div class="single-info-box">
                            <h4>Share</h4>
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                                <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="single-info-box">
                            <a routerLink="/" class="default-btn">Live Preview</a>
                        </div>
                    </div> -->




                </div>
            </div>
        </div>
    </div>
</section>