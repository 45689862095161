import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UwService } from 'src/app/uw.service';
import { emailValidator } from '../common/email-validator.directive.ts.directive';
import emailjs from '@emailjs/browser';

@Component({
  selector: 'app-career-apply',
  templateUrl: './career-apply.component.html', 
  styleUrls: ['./career-apply.component.scss']
})
export class CareerApplyComponent {
  careerData: any;
  id: any;

  websiteList: any[] = [];
  careerStatus: any = ['Currently open', 'Open in 2 weeks', 'Open in a month', 'Looking for part time', 'C2C', 'Looking but not available'];
  submitted = false;
  onChange: Function;
  progress: any;
  imgData:any;
  file: any;

  careerForm = new FormGroup({
    fullname: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30),
    ]),
    location: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(40),
    ]),
    careerchoice: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(40),
    ]),
    careerstatus: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(40),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(50),
      emailValidator(),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(18),
    ]),
    my_file: new FormControl(''),
    acceptTerms: new FormControl(false, Validators.requiredTrue),
  });

  requestStatus: string = '';
  tempRecord: any;
  successCount = 0;

  constructor(
    private route: ActivatedRoute,
    private uwServices: UwService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCareerById();
    emailjs.init('lcmPyx6cBFlxWIbZr');
  }

  // convenience getter for easy access to form fields
  get f() { return this.careerForm.controls; }

  getCareerById() {
    this.uwServices.getCareerData().subscribe({
      next: (val: any)=> {
        if(val?.data) {
          this.careerData = val?.data.filter((v: any) => v?.id === this.id)?.[0];
          if(this.careerData?.list.length > 0) {
            this.careerData?.list.forEach(e => {
              this.websiteList.push(e?.tit.replace(/:/g, ''));
            });
          }
        }
      },
      error: (err) =>{
        console.error(err);
        this.router.navigate(['/error']);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.careerForm.invalid) {
        return;
    }
    if(this.file) {
      if (/\.(pdf)$/i.test(this.file.name)) { 
        this.reader(this.file, (err, res) => {
          this.processFormSubmissionData(res);
        });
        this.ref.markForCheck();
      }
    } else {
      this.processFormSubmissionData('');
    }
  }

  processFormSubmissionData(filevalue) {
    if(this.successCount > 0 && this.tempRecord === this.careerForm.getRawValue()) {
      this.setStatus('dup');
    } else {
      this.tempRecord = this.careerForm.getRawValue();
      const { fullname, careerchoice, careerstatus, email, phone, location} = this.careerForm.getRawValue() ?? {};
      const params = {
        name: fullname || '',
        choice: careerchoice || '',
        status: careerstatus || '',
        email: email || '',
        phone: phone || '',
        location: location || '',
        image: filevalue || '',
      }
      emailjs.send('service_ugohsolu_eacawzf','career_temp_qv502fa', params).then((res) => {
        this.setStatus('success');
        this.successCount++;
      }).catch((err) => {
        this.setStatus('error');
      });
    }
  }


  onFileChange(event) {
    this.requestStatus = '';
    const preview = <HTMLImageElement>document.querySelector("#preview");
    if(event.target.files && event.target.files.length) {
      this.file = event.target.files?.[0];
      if (/\.(pdf)$/i.test(this.file.name)) { 
        this.reader(this.file, (err, res) => {
          preview.width = 100;
          preview.height = 100;
          preview.src = res;
        });
        this.ref.markForCheck();
      } else {
        this.requestStatus = 'file'
      }
    }
  }

  setStatus(status) {
    this.requestStatus = status;
    let el = document.querySelector("#confirmation-section");
    el.scrollIntoView({behavior: 'smooth'});
    setTimeout(() => {
      this.requestStatus = '';
      this.careerForm.reset();
      this.submitted = false;
    }, 7000);
  }

  reader(file, callback) {
    const fr = new FileReader();
    fr.onload = () => callback(null, fr.result);
    fr.onerror = (err) => callback(err);
    fr.readAsDataURL(file);
  }

  onReset() {
    this.submitted = false;
    const preview = document.querySelector("#preview");
    preview.setAttribute('src', "");
    this.careerForm.reset();
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }
  
}
