<app-navbar></app-navbar>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="conditions-section ptb-100">
    <div class="container">
    <div class="single-privacy">
        <p> <strong>Welcome to UgohSolutions formerly ( UgohWorld ), your one-stop shop for all things IT solutions! These Terms and Conditions ("Terms")
        govern your access and use of our website located at www.UgohSolutions.com and all associated services offered by
        ugohsolutions (collectively, the "Website"). </strong> </p> 

        <h3> Acceptance of Terms </h3>
        <p>By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of
            these Terms, you may not access or use the Website.</p>
        <h3>Modifications to Terms</h3>
        <p>UgohSolutions reserves the right to modify these Terms at any time and without prior notice. Your continued use
            of the Website following any such changes constitutes your agreement to the revised Terms.</p>
        <h3>Services Offered</h3>
        <p> UgohSolutions provides a variety of IT solutions, including but not limited to:</p>
        <ul>
            <li> IT consulting services </li>
            <li> Digital marketing services </li>
            <li> Search engine optimization (SEO) </li>
            <li> App development </li>
            <li> Ecommerce website development </li>
            <li> Other IT solutions as specified on the Website </li>
        </ul>
        <h3>User Content</h3>
        <p>You may submit content to the Website, such as comments, reviews, and feedback ("User Content"). You retain
            ownership of your User Content, but you grant ugohsolutions a non-exclusive, worldwide, royalty-free, perpetual,
            irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create
            derivative works from, distribute, and display your User Content in any and all media. You are solely
            responsible for the content of your User Content and any harm resulting from it.</p>
        <h3>Intellectual Property</h3>
        <p>The Website and all content therein, including but not limited to text, graphics, logos, images, software,
            and designs, are the property of ugohsolutions or its licensors and are protected by copyright, trademark, and
            other intellectual property laws. You may not use any of the content on the Website for any commercial
            purpose without the express written permission of ugohsolutions.</p>
        <h3>Disclaimers and Warranties</h3>
        <strong>THE WEBSITE AND ALL SERVICES PROVIDED BY ugohsolutions ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY
            OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. ugohsolutions DOES NOT WARRANT THAT THE WEBSITE WILL BE UNINTERRUPTED,
            ERROR-FREE, OR VIRUS-FREE. YOU USE THE WEBSITE AT YOUR OWN RISK. </strong>
        <h3>Limitation of Liability</h3>
        <strong>ugohsolutions SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
            ARISING OUT OF OR RELATED TO YOUR USE OF THE WEBSITE OR ANY SERVICES PROVIDED BY ugohsolutions, EVEN IF
            UgohSolutions HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong>
        <h3>Indemnification</h3>
        <p>You agree to indemnify and hold harmless ugohsolutions, its officers, directors, employees, agents, and licensors
            from and against any and all claims, losses, damages, liabilities, costs, and expenses (including reasonable
            attorneys' fees) arising out of or relating to your use of the Website or your violation of these Terms.</p>
        <h3>Termination</h3>
        <p>UgohSolutions may terminate your access to the Website at any time and for any reason, with or without notice.
            You may also terminate your access to the Website at any time.</p>
        <h3>Governing Law</h3>
        <p>These Terms and your use of the Website shall be governed by and construed in accordance with the laws of the
            State of PA, without regard to its conflict of laws provisions.</p>
        <h3>Entire Agreement</h3>
        <p>These Terms constitute the entire agreement between you and ugohsolutions with respect to your use of the Website
            and supersede all prior or contemporaneous communications, representations, or agreements, whether oral or
            written.</p>
    </div>

    <div class="single-privacy mt-3">
        <h2>Submitting your project details through our website or any other channel, you agree to the following terms and conditions:</h2>
        <ul>
            <li>
                <h3 class="mt-2"> Confidentiality: </h3>
                <p> Ugoh Solutions LLC understands the sensitive nature of your project information. We will take all reasonable steps to protect the confidentiality of your information.
                    However, we cannot guarantee absolute security due to the nature of electronic communication.
                    We will not share your project details with any third party without your prior written consent, except as necessary to fulfill our obligations to provide you with a quote or complete your project.
                </p>
            </li>
            <li>
                <h3 class="mt-2"> Use of Information:</h3>
                <p> The information you provide will be used by Ugoh Solutions LLC to:
                    Understand your project requirements and needs.
                    Develop a customized quote for your IT project.
                    Communicate with you regarding your project.</p>
                </li>
            <li>
                <h3 class="mt-2"> No Obligation:</h3>
                <p> Submitting your project details does not obligate you to engage Ugoh Solutions LLC for your project. You are free to choose any IT service provider you see fit.</p>
            </li>
            <li>
                <h3 class="mt-2"> Project Ownership:</h3>
                <p> You retain all ownership rights to your project concept, ideas, and data.</p>
            </li>
            <li>
                <h3 class="mt-2"> Governing Law:</h3>
                <p> These terms and conditions shall be governed by and construed in accordance with the laws of the Commonwealth of Pennsylvania, United States of America.</p>
            </li>
            <li>
                <h3 class="mt-2"> Entire Agreement:</h3>
                <p> These terms and conditions constitute the entire agreement between you and Ugoh Solutions LLC regarding the submission of project information.</p>
            </li>
            <li>
                <h3 class="mt-2"> Updates to Terms and Conditions:</h3>
                <p> Ugoh Solutions LLC reserves the right to update these terms and conditions at any time. We will post any changes on our website.</p>
            </li>
        </ul>
    </div>
    <div class="single-privacy mt-3">
        <h3 class="mt-2"> Contact Us:</h3>
        <p> If you have any questions regarding these terms and conditions, please contact us at:
            <br/><strong> Email: info@ugohsolutions.com </strong>
            <br/><strong> Phone: +1 (412) 727-0177 </strong></p>
    </div>
</div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>