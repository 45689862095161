import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  careerInfo: any; 
  careerDetails: any = {};
  constructor(
    private uwServices: UwService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getCareerData();
  }

  getCareerData() {
    this.uwServices.getCareerData().subscribe({
      next: (val: any)=> {
        this.careerInfo = val?.data;
        this.careerDetails = this.careerInfo?.[0];
      },
      error: (err) =>{
        console.error(err);
      }
    });
  }

  careerDisplay(career) {
    this.careerDetails = career;
  }

  applyToCareer(career) {
    this.router.navigate(["/career-apply", career?.id]);
  }
}
