<app-navbar></app-navbar>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="protfolio-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Projects <span>we</span> have contributed services</h2>
            <p>Our IT solution services, technical supports and developments have been contributed to the below projects.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let prj of projectsInfo">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a [routerLink]="['/projects-details',prj.id]"><img src="assets/img/protfolio/{{prj.img1}}" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>{{prj?.name}}</h3>
                        <span><strong>We did:</strong> {{prj?.wedid}}</span>
                        <a [routerLink]="['/projects-details',prj.id]" class="link-btn"></a>
                    </div>
                </div>
            </div> 

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/projects-two" class="prev page-numbers"><i class="flaticon-left"></i></a>
                    <a routerLink="/projects-two" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/projects-two" class="page-numbers">3</a>
                    <a routerLink="/projects-two" class="page-numbers">4</a>
                    <a routerLink="/projects-two" class="next page-numbers"><i class="flaticon-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>