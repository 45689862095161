<app-navbar></app-navbar>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Career</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Career - IT recruitments and Talent acquisition</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="tab-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Make a <span>Difference</span> in the IT Industry</h2>
            <p>The thrill of untangling complex technical issues and finding elegant solutions can be a major motivator for IT personnel. You are here because you are an IT personnel, please choose your IT choice below to apply and submit your resume. </p>
            <div class="bar"></div>
        </div>
        <div class="tab schedule-list-tab">
            <ul class="tabs">
                <li *ngFor="let career of careerInfo" class="mb-3">
                    <a href="#" style="cursor: pointer" (click)="careerDisplay(career)">
                        <span>
                            <i [class]="career?.icon"></i> 
                            {{career?.name}}
                        </span>
                    </a>
                </li>
                <!-- <li><a href="#"><span><i class="flaticon-analysis"></i> Real-Time Analytics</span></a></li>
                <li><a href="#"><span><i class="flaticon-optimize"></i> Pay-Per-Click</span></a></li>
                <li><a href="#"><span><i class="flaticon-software"></i> Online Marketing</span></a></li>
                <li><a href="#"><span><i class="flaticon-internet"></i> Email Marketing</span></a></li>
                <li><a href="#"><span><i class="flaticon-data"></i> Social Marketing</span></a></li> -->
            </ul>

            <div class="tab_content">
                <div class="tabs_item career" *ngIf="careerDetails" style="display: block !important;">
                    <div class="row align-items-center">
                        <div class="row pb-3">
                            <div class="col-lg-12 col-md-12 tab-content" *ngIf="careerDetails">
                                <h2>{{ careerDetails?.name }}</h2>
                                <p>{{ careerDetails?.summary }}</p> 
                                <p [innerHTML]="careerDetails?.body"></p> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="tab-content" *ngIf="careerDetails">
                                    <div class="tab-inner-content" *ngFor="let l of careerDetails?.list">
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        <h3 [innerHTML]="l?.tit"></h3>
                                        <p [innerHTML]="l?.des"></p>
                                    </div>
                                    <p class="mt-3" [innerHTML]="careerDetails?.desc2"></p>
                                    <div class="text-left mb-3 mt-3">
                                        <button class="btn btn-primary mr-1" (click)="applyToCareer(careerDetails)">Apply Now</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="tab-image pt-5">
                                    <img [src]="'assets/img/career/'+careerDetails?.img1" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>