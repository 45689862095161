import { Component, OnInit } from '@angular/core';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  serviceInfo: any;
  constructor(private uwServices: UwService) { }

  ngOnInit(): void {
    this.getServicesData();
  }

  getServicesData() {
    this.uwServices.getServicesData().subscribe({
      next: (val: any)=> {
        this.serviceInfo = val;
      },
      error: (err) =>{
        console.error(err);
      }
    });
  }

}
