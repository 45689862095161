<app-navbar></app-navbar>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section bg-color pt-50 pb-30">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Our IT solution services are a broad range of services that help businesses improve their IT infrastructure and operations.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let serv of this.serviceInfo?.data">
                <div class="single-services-box">
                    <div [class]="'icon '+ serv.bgicon">
                        <i [ngClass]="serv.icon"></i>
                    </div>
                    <h3>{{ serv.title }}</h3>
                    <p>{{ serv.summary }}</p>
                    <a [routerLink]="['/services-details',serv.id]" class="read-btn">Read More</a>
                </div>
            </div>

        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
    
    <div class="services-shape">
        <img src="assets/img/cloud.png" alt="image">
    </div>
</section>

<section class="faq-section pb-50 pt-10">
    <div class="container">
        <div class="section-title">
            <h2>Frequently <span>Asked</span> Questions</h2>
            <p>We encourage you to contact us with any questions you may have. We are happy to help!.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">What services does ugohsolutions offer? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <div class="tabs_item">
                                    <p>UgohSolutions offers a wide range of IT consulting services, including:</p>
                                    <ul class="list" style="list-style: none;">
                                        <li><strong>IT strategy and planning:</strong> We will help you develop a comprehensive IT strategy that aligns with your business goals.</li>
                                        <li><strong>Cloud computing:</strong> We can help you migrate your IT infrastructure to the cloud and take advantage of the scalability, cost savings, and agility it offers.</li>
                                        <li><strong>Cybersecurity:</strong> We will help you protect your data and systems from cyberattacks.</li>
                                        <li><strong>IT support:</strong> We offer 24/7/365 IT support to ensure that your business is always up and running.</li>
                                        <li><strong>Data analytics:</strong> We can help you collect, analyze, and interpret your data to gain valuable insights into your business.</li>
                                        <li><strong>Artificial intelligence and machine learning:</strong> We can help you implement AI and ML solutions to automate tasks, improve decision-making, and gain a competitive edge.</li>
                                        <li><strong>Business process automation:</strong> We can help you automate your business processes to improve efficiency and reduce costs.</li>
                                        <li><strong>Enterprise resource planning (ERP):</strong> We can help you implement an ERP system to integrate all your business processes and improve data visibility.</li>
                                        <li><strong>Customer relationship management (CRM):</strong> We can help you implement a CRM system to track and manage your interactions with customers.</li>
                                        <li><strong>Supply chain management (SCM):</strong> We can help you optimize your supply chain to improve efficiency and reduce costs.</li>
                                        <li><strong>Project management:</strong> We can help you manage your IT projects effectively.</li>
                                        <li><strong>Change management:</strong> We can help you implement changes to your IT infrastructure and processes smoothly.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What are the benefits of working with UgohSolutions? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <div class="tabs_item">
                                    <p>There are many benefits to working with UgohSolutions, including:</p>
                                    <ul class="list" style="list-style: none;">
                                        <li><strong>Expertise:</strong> Our team of IT professionals has extensive experience in a wide range of technologies.</li>
                                        <li><strong>Experience:</strong> We have a proven track record of success in helping businesses achieve their IT goals.</li>
                                        <li><strong>Customer focus:</strong> We are committed to providing our clients with the best possible service and support.</li>
                                        <li><strong>Cost-effective:</strong> We offer competitive rates for our services.</li>
                                        <li><strong>Scalability:</strong> We can scale our services to meet the needs of businesses of all sizes.</li>
                                        <li><strong>Flexibility:</strong> We offer a flexible range of services to meet your specific needs.</li>
                                        <li><strong>Security:</strong> We take data security very seriously and have implemented a number of measures to protect our clients' data.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How do I get started with UgohSolutions? <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseThree" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>To get started with UgohSolutions, simply contact us today for a free consultation. We will be happy to discuss your specific needs and how we can help you achieve your IT goals.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Other related questions you can ask: <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <div class="tabs_item">
                                    <p>We encourage you to ask us any question.</p>
                                    <ul class="list" style="list-style: none;">
                                        <li><strong>What are your rates?</strong></li>
                                        <li><strong>Do you offer discounts for long-term contracts?</strong></li>
                                        <li><strong>What are your service hours?</strong></li>
                                        <li><strong>What is your response time for support tickets?</strong></li>
                                        <li><strong>Do you offer training?</strong></li>
                                        <li><strong>What are your qualifications?</strong></li>
                                        <li><strong>Do you have any references?</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/serv1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
