<app-navbar></app-navbar>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog / News</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Tech related latest news</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="section-title">
                <h2>Tech Related <span>Latest</span> News</h2>
                <p>We are committed to providing businesses with the information and resources they need to succeed in today's digital world. We share Computer Weekly blog news, which is updated regularly with news and insights on the latest IT trends.</p>
                <div class="bar"></div>
            </div>
            <div class="col-lg-4 col-md-6" *ngFor="let rss of rssData; let ind = index;">
                <div class="single-blog-item" *ngIf="ind <= 11">
                    <div class="image">
                        <a [href]="rss?.guid" target="_blank"><img [src]="rss?.image" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>{{rss?.published | date}}</span> - <span>About {{rss?.category}}</span>
                        <h3><a [href]="rss?.guid" target="_blank">{{rss?.title}}</a></h3>
                        <p>{{rss?.description}}</p>
                        <a [href]="rss?.link" target="_blank" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            
            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog-grid" class="prev page-numbers"><i class="flaticon-left"></i></a>
                    <a routerLink="#" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog-grid" class="page-numbers">4</a>
                    <a routerLink="/blog-grid" class="next page-numbers"><i class="flaticon-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>