import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { emailValidator } from '../common/email-validator.directive.ts.directive';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(50),
      emailValidator(),
    ]),
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
    ]),
    company: new FormControl(''),
    subject: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  requestStatus: string = '';
  tempRecord: any;
  successCount = 0;
  submitted = false;
  
  constructor() { }

  ngOnInit(): void {
    emailjs.init('lcmPyx6cBFlxWIbZr');
  }

  sendMail() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      for (const control of Object.keys(this.contactForm.controls)) {
        this.contactForm.controls[control].markAsTouched();
      }
      return;
    }

    if(this.successCount > 0 && this.tempRecord === this.contactForm.getRawValue()) {
      this.setStatus('dup');
    } else {
      this.tempRecord = this.contactForm.getRawValue();
      const { name, email, company, subject, message} = this.contactForm.getRawValue() ?? {};
      const param = {
        from_name: name,
        email_id: email,
        company: company,
        subject: subject,
        message: message,
      }
      emailjs.send('service_ugohsolu_eacawzf','template_5p79cvu', param).then((res) => {
        this.setStatus('success');
        this.successCount++;
      }).catch((err) => {
        this.setStatus('error');
      });
    }
    
  }

  setStatus(status) {
    this.requestStatus = status;
    setTimeout(() => {
      this.requestStatus = '';
      this.contactForm.reset();
      this.submitted = false;
    }, 7000);
  }

}
