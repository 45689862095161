import { Component, OnInit } from '@angular/core';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  partnersInfo: any;
  constructor(private uwServices: UwService) {}
  ngOnInit(): void {
    this.getPartnerData();
  }

  getPartnerData() {
    this.uwServices.getPartners().subscribe({
      next: (val: any)=> {
        this.partnersInfo = val;
      },
      error: (err) =>{
        console.error(err);
      }
    });
  }

}
