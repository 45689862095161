<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="alert alert-success" *ngIf="newsletterStatus === 'success'">
                        <strong>Success! :</strong>  You successfully subscribed to our newsletters.
                    </div>
                    <div class="alert alert-danger" *ngIf="newsletterStatus === 'error'">
                        <strong>Error! :</strong> Request failed - Please try again.
                    </div>
                    <div class="alert alert-warning" *ngIf="newsletterStatus === 'dup'">
                        <strong>Warning! :</strong> You have submitted this email already.
                    </div>
                    <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="submitNewsLetter()" *ngIf="!['success', 'error', 'dup'].includes(newsletterStatus)">
                        <input 
                            type="email"
                            class="input-newsletter"
                            placeholder="Enter your email"
                            name="email"
                            [ngClass]="{'is-invalid': newsLetterSubmitted && newsletterForm.controls.email.errors && (newsletterForm.controls.email.dirty || newsletterForm.controls.email.touched)}"
                            required
                            minlength="6"
                            maxlength="50"
                            formControlName="email"
                            >
                        <div *ngIf="newsLetterSubmitted && newsletterForm.controls.email.invalid && (newsletterForm.controls.email.dirty || newsletterForm.controls.email.touched)" class="invalid-feedback">
                            <div *ngIf="newsletterForm.controls.email.errors?.['required']">
                                This email field is required.
                            </div>
                            <div *ngIf="newsletterForm.controls.email.errors?.['minlength']">
                                This email field must have at least 6 character.
                            </div>
                            <div *ngIf="newsletterForm.controls.email.errors?.['maxlength']">
                                This email field must have at most 50 characters.
                            </div>
                            <div *ngIf="!newsletterForm.controls.email.errors?.['required'] && !newsletterForm.controls.email.errors?.['minlength'] && !newsletterForm.controls.email.errors?.['maxlength'] && newsletterForm.controls.email.errors?.['emailValidator']">
                                Invalid email format.
                            </div>
                        </div>
                        <button type="submit">Subscribe Now</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>About Us</h3>
                    </div>
                    <p>We are IT consultancy and recruitment services. We optimize your business internet technology infrastructure to empower your business growth, from small businesses to large enterprises. </p>
                    <ul class="footer-social">
                        <li><a href="https://www.facebook.com/ugohsolutions" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="https://x.com/UgohSolutions" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/ugohsolutions/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Important Links</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/projects">Project</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/news">News</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/career">Career</a></li>
                    </ul>
                </div>    
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Featured Service</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/services-details/73636">IT Consulting / Recruiting</a></li>
                        <li><a routerLink="/services-details/73637">Web Development</a></li>
                        <li><a routerLink="/services-details/736466">Digital Marketing / SEOs</a></li>
                        <li><a routerLink="/services-details/73638">App Development</a></li>
                        <li><a routerLink="/services-details/73639">E-Commerce Development</a></li>
                        <li><a routerLink="/services-details/73640">IT Solutions</a></li>
                    </ul>
                </div>    
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contact</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>Phone</h3>
                        <span><a href="tel:+14127270177">+1 (412) 727-0177</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="mailto:info@ugohsolutions.com">info@ugohsolutions.com</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>Address</h3>
                        <span>Reading, PA. USA</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Ugoh Solutions LLC 2024 </p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>We are IT consultancy and recruitment services. We optimize your business internet technology infrastructure to empower your business growth, from small businesses to large enterprises.</p>
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="alert alert-success" *ngIf="requestStatus === 'success'">
                        <strong>Success! :</strong> Request was submitted successfully.
                    </div>
                    <div class="alert alert-danger" *ngIf="requestStatus === 'error'">
                        <strong>Error! :</strong> Request failed - Please try again.
                    </div>
                    <div class="alert alert-warning" *ngIf="requestStatus === 'dup'">
                        <strong>Warning! :</strong> You have submitted this records already.
                    </div>
                    <div class="contact-text">
                        <h3>Have Any Questions For Us?</h3>
                        <p>Please use the below form to submit an inquiry.</p>
                    </div>
                    <div class="contact-form">
                        <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="sendMail()" #form="ngForm" *ngIf="!['success', 'error', 'dup'].includes(requestStatus)">
                            <div class="form-group">
                                <label>Name: 
                                    <strong style="color: red;">*</strong>
                                </label>
                                <input type="text" name="name" id="name" formControlName="name" class="form-control" placeholder="Name" required minlength="3" maxlength="20" [ngClass]="{'is-invalid': submitted && contactForm.controls.name.errors && (contactForm.controls.name.dirty || contactForm.controls.name.touched)}">
                                <div *ngIf="submitted && contactForm.controls.name.invalid && (contactForm.controls.name.dirty || contactForm.controls.name.touched)" class="invalid-feedback">
                                    <div *ngIf="contactForm.controls.name.errors?.['required']">
                                      This name field is required.
                                    </div>
                                    <div *ngIf="contactForm.controls.name.errors?.['minlength']">
                                      This name field must have at least 3 character.
                                    </div>
                                    <div *ngIf="contactForm.controls.name.errors?.['maxlength']">
                                      This name field must have at most 20 characters.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Email: <strong style="color: red;">*</strong></label>
                                <input type="email" name="email" id="email" formControlName="email" class="form-control" placeholder="Your Email" required minlength="6" maxlength="50" class="form-control form-control-sm" [ngClass]="{'is-invalid': submitted && contactForm.controls.email.errors && (contactForm.controls.email.dirty || contactForm.controls.email.touched)}">
                                <div *ngIf="submitted && contactForm.controls.email.invalid && (contactForm.controls.email.dirty || contactForm.controls.email.touched)" class="invalid-feedback">
                                    <div *ngIf="contactForm.controls.email.errors?.['required']">
                                      This email field is required.
                                    </div>
                                    <div *ngIf="contactForm.controls.email.errors?.['minlength']">
                                      This email field must have at least 6 character.
                                    </div>
                                    <div *ngIf="contactForm.controls.email.errors?.['maxlength']">
                                      This email field must have at most 50 characters.
                                    </div>
                                    <div *ngIf="!contactForm.controls.email.errors?.['required'] && !contactForm.controls.email.errors?.['minlength'] && !contactForm.controls.email.errors?.['maxlength'] && contactForm.controls.email.errors?.['emailValidator']">
                                      Invalid email format.
                                    </div>
                                  </div>
                            </div>
                            <div class="form-group">
                                <label>Company</label>
                                <input type="text" name="company" id="company" formControlName="company" class="form-control" placeholder="Your Company (optional)">
                            </div>
                            <div class="form-group">
                                <label>Subject: <strong style="color: red;">*</strong></label>
                                <input type="text" name="msg_subject" id="msg_subject" formControlName="subject" class="form-control" placeholder="Your Subject" required minlength="3" maxlength="50" [ngClass]="{'is-invalid': submitted && contactForm.controls.subject.errors && (contactForm.controls.subject.dirty || contactForm.controls.subject.touched)}">
                                <div *ngIf="submitted && contactForm.controls.subject.invalid && (contactForm.controls.subject.dirty || contactForm.controls.subject.touched)" class="invalid-feedback">
                                    <div *ngIf="contactForm.controls.subject.errors?.['required']">
                                      This subject field is required.
                                    </div>
                                    <div *ngIf="contactForm.controls.subject.errors?.['minlength']">
                                      This subject field must have at least 3 character.
                                    </div>
                                    <div *ngIf="contactForm.controls.subject.errors?.['maxlength']">
                                      This subject field must have at most 50 characters.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Message: <strong style="color: red;">*</strong></label>
                                <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="6" placeholder="Your Message" required minlength="10" maxlength="10000" [ngClass]="{'is-invalid': submitted && contactForm.controls.message.errors && (contactForm.controls.message.dirty || contactForm.controls.message.touched)}"></textarea>
                                <div *ngIf="submitted && contactForm.controls.message.invalid && (contactForm.controls.message.dirty || contactForm.controls.message.touched)" class="invalid-feedback">
                                    <div *ngIf="contactForm.controls.message.errors?.['required']">
                                      This message field is required.
                                    </div>
                                    <div *ngIf="contactForm.controls.message.errors?.['minlength']">
                                      This message field must have at least 10 character.
                                    </div>
                                    <div *ngIf="contactForm.controls.message.errors?.['maxlength']">
                                      This message field must have at most 10000 characters.
                                    </div>
                                </div>
                            </div>
                            <div class="send-btn">
                                <button type="submit" class="default-btn">Send Inquiry</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            
            
            
            <!-- <div class="contact-form"> 
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="company" id="company" class="form-control" placeholder="Your company (optional)">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div> -->
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+14127270177">+1 (412) 727-0177</a>
                        <span>OR</span>
                        <a href="mailto:info@ugohsolutions.com">info@ugohsolutions.com</a>
                        <a href="mailto:services@ugohsolutions.com">services@ugohsolutions.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="facebook.com/ugohsolutionsd" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ugohsolutions" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/ugohsolutions/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>