<app-navbar></app-navbar>

<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{ careerData?.name }}</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Apply to this career</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
</div>

<section class="services-details-area ptb-30">
    <div class="container">
        <div class="services-details-video">
            <div class="text">
                <h3>Become A {{ careerData?.title }} by applying today.</h3>
                <p> <strong> {{ careerData?.summary }}</strong></p>
                <p [innerHTML]="careerData?.body"></p>
            </div>
        </div>
    </div>
</section>

<section class="contact-box pt-10 pb-10">
    <div class="container">
        <div class="row">
            <div class="card m-3">
                <h5 class="card-header">Provide this informations </h5>
                <div class="card-body">
                    <div class="m-3" id="confirmation-section">
                        <div class="alert alert-success" *ngIf="requestStatus === 'success'">
                            <strong>Success! :</strong> Request was submitted successfully.
                        </div>
                        <div class="alert alert-danger" *ngIf="requestStatus === 'error'">
                            <strong>Error! :</strong> Request failed - Please try again.
                        </div>
                        <div class="alert alert-warning" *ngIf="requestStatus === 'dup'">
                            <strong>Warning! :</strong> You have submitted this records already.
                        </div>
                        <div class="alert alert-danger" *ngIf="requestStatus === 'file'">
                            <strong>Error on file upload :</strong> File format not accepted or File size should be less than 30mb.
                        </div>
                    </div>


                    <form [formGroup]="careerForm" (ngSubmit)="onSubmit()" *ngIf="!['success', 'error', 'dup'].includes(requestStatus)">
                        <div class="row pb-4">
                            <div class="form-group col-lg-6 pb-3">
                                <label>Fullname: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="fullname" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.fullname?.errors}" />
                                <div *ngIf="f.fullname.invalid && (f.fullname.dirty || f.fullname.touched)" class="invalid-feedback">
                                    <div *ngIf="f.fullname.errors?.['required']">
                                      This first name field is required.
                                    </div>
                                    <div *ngIf="f.fullname.errors?.['minlength']">
                                      This first name field must have at least 1 character.
                                    </div>
                                    <div *ngIf="f.fullname.errors?.['maxlength']">
                                      This first name field must have at most 20 characters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6 pb-3">
                                <label>Email: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="email" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f?.email?.errors}" />
                                <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="invalid-feedback">
                                    <div *ngIf="f.email.errors?.['required']">
                                      This email field is required.
                                    </div>
                                    <div *ngIf="f.email.errors?.['minlength']">
                                      This email field must have at least 6 character.
                                    </div>
                                    <div *ngIf="f.email.errors?.['maxlength']">
                                      This email field must have at most 50 characters.
                                    </div>
                                    <div *ngIf="!f.email.errors?.['required'] && !f.email.errors?.['minlength'] && !f.email.errors?.['maxlength'] && f.email.errors?.['emailValidator']">
                                      Invalid email format.
                                    </div>
                                  </div>
                            </div>

                            <div class="form-group col-lg-6">
                                <label>Phone number: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="phone" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="invalid-feedback">
                                    <div *ngIf="f.phone.errors?.['required']">
                                      This phone field is required.
                                    </div>
                                    <div *ngIf="f.phone.errors?.['minlength']">
                                      This phone field must have at least 5 character.
                                    </div>
                                    <div *ngIf="f.phone.errors?.['maxlength']">
                                      This phone field must have at most 18 characters.
                                    </div>
                                    <div *ngIf="!f.phone.errors?.['required'] && !f.phone.errors?.['minlength'] && !f.phone.errors?.['maxlength'] && f.phone.errors?.['pattern']">
                                      Invalid phone format.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6 pb-3">
                                <label for="careerchoice">Choice of {{ careerData?.title }}: <strong style="color: red;">*</strong></label>  

                                <select formControlName="careerchoice" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.careerchoice.errors }">  
                                    <option disabled>Select career specific</option>  
                                    <option>Choose specific</option>  
                                    <option *ngFor="let web of websiteList">{{web}}</option>  
                                </select>  
                                <div *ngIf="f.careerchoice.touched && f.careerchoice.invalid" class="alert alert-danger">  
                                    <div>Career specific is required.</div>  
                                </div>
                            </div>

                            <div class="form-group col-lg-6 pb-3">
                                <label for="careerstatus">Current status: <strong style="color: red;">*</strong></label>  
                                <select formControlName="careerstatus" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.careerstatus.errors }">  
                                    <option disabled>Select status</option>  
                                    <option>Choose status</option>  
                                    <option *ngFor="let stas of careerStatus">{{stas}}</option>  
                                </select>  
                                <div *ngIf="f.careerstatus.touched && f.careerstatus.invalid" class="alert alert-danger">  
                                    <div>Your current status is required.</div>  
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6 pb-3">
                                <label>Current location: <strong style="color: red;">*</strong></label>
                                <input type="text" formControlName="location" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.location?.errors}" />
                                <div *ngIf="f.location.invalid && (f.location.dirty || f.location.touched)" class="invalid-feedback">
                                    <div *ngIf="f.location.errors?.['required']">
                                      This current location field is required.
                                    </div>
                                    <div *ngIf="f.location.errors?.['minlength']">
                                      This current location field must have at least 1 character.
                                    </div>
                                    <div *ngIf="f.location.errors?.['maxlength']">
                                      This current location field must have at most 20 characters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pb-4">
                            <div class="form-group col-lg-6">
                                <label class="mb-2">Upload Resume ( PDF file only ) <strong style="color: red;">*</strong></label><br>
                                <input class="file-input" type="file" name="my_file" formControlName="my_file" (change)="onFileChange($event)" />
                                <img src="" height="200" id="preview"/> 
                                <div style="color: red;" *ngIf="requestStatus === 'file'">
                                    PDF file only.
                                </div>
                            </div>
                        </div>
                        <div class="form-group form-check mb-3 hover-text">
                            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                            <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions: <strong style="color: red;">*</strong></label>
                            <span class="tooltip-text top">By submitting your project details, you acknowledge that you have read, understood, and agree to our <a routerLink="/terms-conditions" style="color: blue" target="_blank"> terms and conditions</a>.</span>
                            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Acknowledgment is required</div>
                        </div>
                        <div class="text-left mb-3">
                            <button class="btn btn-primary mr-1">Submit application</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>