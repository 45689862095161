import { Component, OnInit } from '@angular/core';
import { UwService } from 'src/app/uw.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projectsInfo: any;
  constructor(private uwServices: UwService) { }

  ngOnInit(): void {
    this.getProjectsData();
  }

  getProjectsData() {
    this.uwServices.getProjects().subscribe({
      next: (val: any)=> {
        this.projectsInfo = val?.data;
      },
      error: (err) =>{
        console.error(err);
      }
    });
  }
}
